import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios'
import Content from '../components/layout/Content'
import Arrow from '../icons/Arrow'
import styles from '../styles/Challenge.module.css'

const API_URL = process.env.REACT_APP_API_BASEURL
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN

function Challenge() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { challengeId } = useParams()
  const nameInput = React.useRef(null)
  const questionOneInput = React.useRef(null)
  const questionTwoInput = React.useRef(null)
  const questionThreeInput = React.useRef(null)
  const [challenge, setChallenge] = React.useState(null)
  const [fetching, setFetching] = React.useState(false)
  const [teams, setTeams] = React.useState([])
  const [fetchingTeams, setFetchingTeams] = React.useState(false)
  const [editName, setEditName] = React.useState(false)
  const [updatedName, setUpdatedName] = React.useState('')
  const [editQuestionOne, setEditQuestionOne] = React.useState(false)
  const [updatingName, setUpdatingName] = React.useState(false)
  const [updatedQuestionOne, setUpdatedQuestionOne] = React.useState('')
  const [editQuestionTwo, setEditQuestionTwo] = React.useState(false)
  const [updatedQuestionTwo, setUpdatedQuestionTwo] = React.useState('')
  const [editQuestionThree, setEditQuestionThree] = React.useState(false)
  const [updatedQuestionThree, setUpdatedQuestionThree] = React.useState('')
  const [updatingQuestionOne, setUpdatingQuestionOne] = React.useState(false)
  const [updatingQuestionTwo, setUpdatingQuestionTwo] = React.useState(false)
  const [updatingQuestionThree, setUpdatingQuestionThree] = React.useState(false)

  const getChallengeById = React.useCallback(async () => {
    setFetching(true)
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${AUTH_DOMAIN}/api/v2/`,
        scope: "read:current_user",
      });

      const { data } = await axios.get(`${API_URL}/challenges/${challengeId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      if(data) {
        setChallenge({
          ...data.challenge,
          questions: data?.questions || []
        })
      } else {
        setChallenge(null)
      }
    } catch (error) {
      console.log(error)
      setChallenge(null)
    }
    setFetching(false)
  }, [challengeId, getAccessTokenSilently])


  const getTeams = React.useCallback(async () => {
    const { link_id } = challenge || {}
    if(link_id) {
      setFetchingTeams(true)
      try {

        const accessToken = await getAccessTokenSilently({
          audience: `https://${AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        });

        if(accessToken){
          const { data } = await axios.get(`${API_URL}/links/${link_id}/teams`, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          })
          if(data && data.hasOwnProperty('teams')) {
            setTeams(data.teams)
          }
        }
      } catch (error) {
        console.log(error)
      }
      setFetchingTeams(false)
    }
  }, [challenge, getAccessTokenSilently])

  React.useEffect(() => {
    if(challenge && challenge.hasOwnProperty('link_id')) {
      getTeams()
    }
  }, [challenge, getTeams])

  React.useEffect(() => {
    if(challengeId) {
      getChallengeById()
    }
  }, [challengeId, getChallengeById])

  React.useEffect(() => {
    if(challenge && challenge.hasOwnProperty('name')) {
      setUpdatedName(challenge.name)
    }
    if(challenge?.questions && challenge?.questions.length > 2) {
      setUpdatedQuestionOne(challenge.questions[0]?.question)
      setUpdatedQuestionTwo(challenge.questions[1]?.question)
      setUpdatedQuestionThree(challenge.questions[2]?.question)
    }
  }, [challenge])

  function getFormattedDate(dateString) {
    const date = new Date(dateString)
    const localeDate = date.toLocaleDateString()

    return `${localeDate}`
  }

  function onEditName() {
    setEditName(true)
    if(nameInput) {
      nameInput.current.focus()
    }
  }
  function onEditQuestionOne(question) {
    setEditQuestionOne(true)
    if(questionOneInput) {
      questionOneInput.current.focus()
    }
  }
  function onEditQuestionTwo(question) {
    setEditQuestionTwo(true)
    if(questionTwoInput) {
      questionTwoInput.current.focus()
    }
  }
  function onEditQuestionThree(question) {
    setEditQuestionThree(true)
    if(questionThreeInput) {
      questionThreeInput.current.focus()
    }
  }

  async function onSaveName() {
    setUpdatingName(true)
    try {
      const data = {}
      if(data && data?.success === true) {
        setChallenge(prev => ({
          ...prev,
          name: updatedName
        }))
        setEditName(false)
      }
    } catch (error) {
      console.log(error)
    }
    setUpdatingName(false)
  }
  async function onSaveQuestionOne(questionId) {
    setUpdatingQuestionOne(true)
    const response = await updateQuestion(questionId, updatedQuestionOne)
    if(response === true) {
      setChallenge(prev => {
        return {
          ...prev,
          questions: prev.questions.map(q => {
            if(q.id === questionId) {
              return {
                ...q,
                question: updatedQuestionOne
              }
            }
            return q
          })
        }
      })
      setEditQuestionOne(false)
    }
    setUpdatingQuestionOne(false)
  }
  async function onSaveQuestionTwo(questionId) {
    setUpdatingQuestionTwo(true)
    const response = await updateQuestion(questionId, updatedQuestionTwo)
    if(response === true) {
      setChallenge(prev => {
        return {
          ...prev,
          questions: prev.questions.map(q => {
            if(q.id === questionId) {
              return {
                ...q,
                question: updatedQuestionTwo
              }
            }
            return q
          })
        }
      })
      setEditQuestionTwo(false)
    }
    setUpdatingQuestionTwo(false)
  }
  async function onSaveQuestionThree(questionId) {
    setUpdatingQuestionThree(true)
    const response = await updateQuestion(questionId, updatedQuestionThree)
    if(response === true) {
      setChallenge(prev => {
        return {
          ...prev,
          questions: prev.questions.map(q => {
            if(q.id === questionId) {
              return {
                ...q,
                question: updatedQuestionThree
              }
            }
            return q
          })
        }
      })
      setEditQuestionThree(false)
    }
    setUpdatingQuestionThree(false)
  }

  async function updateQuestion(questionId, question) {

    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${AUTH_DOMAIN}/api/v2/`,
        scope: "read:current_user",
      });
      
      const { data } = await axios.patch(`${API_URL}/questions/${questionId}`, {
        question
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })

      if(data && data?.success === true) {
        return true
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  }
  
  return isAuthenticated && (
    <div>
      <div className={styles.subHeader}>
        <Content>
          <Link className={styles.backButton} to="/challenges">
            <Arrow className={styles.backIcon} direction="left" color="#cf2e05" />
            Tillbaka
          </Link>
        </Content>
      </div>
      <section className={styles.challenge}>
      {fetching === true ? (
        <div className={styles.loading}>
         <Content>
           <p className={styles.loadingText}>Hämtar tävling…</p>
         </Content>
       </div>
      ) : challenge ? (
        <Content>
            <div className={editName === true ? styles.editName : styles.hidden}>
              <input style={{ width: `${updatedName.toString().length}ch`}} placeholder="Ange tävlingsnamn" className={editName === true ? styles.editNameInput : styles.hidden} ref={nameInput} type="text" value={updatedName} onChange={e => setUpdatedName(e.target.value)} />
              <button disabled={updatingName} onClick={onSaveName} className={styles.saveNameButton} type="button">Spara</button>
            </div>
            <h1 className={editName === true ? styles.hidden : styles.heading}>{challenge.name} <button onClick={onEditName} className={styles.changeButton} type="button">Ändra</button></h1>
          <div className={styles.content}>
            <div className={styles.challengeInfo}>
              {challenge?.label && (
                <p>Label: {challenge?.label}</p>
              )}
              <div className={styles.dates}>
                <p>Startdatum: {getFormattedDate(challenge.dateFrom)}</p>
                <p>Slutdatum: {getFormattedDate(challenge.dateTo)}</p>
              </div>
              <h2 className={styles.subHeading}>Frågor</h2>
              {challenge?.questions && challenge?.questions.length > 2 && (
                <ul className={styles.list}>
                  <li className={styles.item}>
                    <div className={editQuestionOne === true ? styles.editQuestionOne : styles.hidden}>
                      <input style={{ width: `${editQuestionOne.toString().length}ch`}} placeholder="Ange fråga" className={editQuestionOne === true ? styles.editQuestionInput : styles.hidden} ref={questionOneInput} type="text" value={updatedQuestionOne} onChange={e => setUpdatedQuestionOne(e.target.value)} />
                      <button disabled={updatingQuestionOne} onClick={() => onSaveQuestionOne(challenge?.questions[0]?.id)} className={styles.saveQuestionButton} type="button">Spara</button>
                    </div>
                    <div className={editQuestionOne === true ? styles.hidden : styles.questionItem}>
                      <span>{challenge?.questions[0]?.question} <button onClick={() => onEditQuestionOne(challenge?.questions[0]?.id)} className={styles.changeButton} type="button">Ändra</button></span>  
                    </div>
                    <p>Label: {challenge?.questions[0]?.label}</p>
                    <p>Svar från {challenge?.questions[0]?.min}–{challenge?.questions[0]?.max}</p>
                  </li>
                  <li className={styles.item}>
                    <div className={editQuestionTwo === true ? styles.editQuestionTwo : styles.hidden}>
                      <input style={{ width: `${editQuestionTwo.toString().length}ch`}} placeholder="Ange fråga" className={editQuestionTwo === true ? styles.editQuestionInput : styles.hidden} ref={questionTwoInput} type="text" value={updatedQuestionTwo} onChange={e => setUpdatedQuestionTwo(e.target.value)} />
                      <button disabled={updatingQuestionTwo} onClick={() => onSaveQuestionTwo(challenge?.questions[1]?.id)} className={styles.saveQuestionButton} type="button">Spara</button>
                    </div>
                    <div className={editQuestionTwo === true ? styles.hidden : styles.questionItem}>
                      <span>{challenge?.questions[1]?.question} <button onClick={() => onEditQuestionTwo(challenge?.questions[1]?.id)} className={styles.changeButton} type="button">Ändra</button></span>  
                    </div>
                    <p>Label: {challenge?.questions[1]?.label}</p>
                    <p>Svar från {challenge?.questions[1]?.min}–{challenge?.questions[1]?.max}</p>
                  </li>
                  <li className={styles.item}>
                    <div className={editQuestionThree === true ? styles.editQuestionThree : styles.hidden}>
                      <input style={{ width: `${editQuestionThree.toString().length}ch`}} placeholder="Ange fråga" className={editQuestionThree === true ? styles.editQuestionInput : styles.hidden} ref={questionThreeInput} type="text" value={updatedQuestionThree} onChange={e => setUpdatedQuestionThree(e.target.value)} />
                      <button disabled={updatingQuestionThree} onClick={() => onSaveQuestionThree(challenge?.questions[2]?.id)} className={styles.saveQuestionButton} type="button">Spara</button>
                    </div>
                    <div className={editQuestionThree === true ? styles.hidden : styles.questionItem}>
                      <span>{challenge?.questions[2]?.question} <button onClick={() => onEditQuestionThree(challenge?.questions[2]?.id)} className={styles.changeButton} type="button">Ändra</button></span>
                    </div>
                    <p>Label: {challenge?.questions[2]?.label}</p>
                    <p>Svar från {challenge?.questions[2]?.min}–{challenge?.questions[2]?.max}</p>
                  </li>
                </ul>
              )}
              {challenge.link_id && (
                <div className={styles.createTeamActions}>
                <Link className={styles.createTeamButton} to={`/challenges/${challenge.link_id}/team`}>
                  Skapa team
                </Link>
                <p className={styles.createTeamText}>eller dela denna länk: <a className={styles.createTeamLink} href={`${window.location.origin}/challenges/${challenge.link_id}/team`}>{`${window.location.origin}/challenges/${challenge.link_id}/team`}</a></p>
                </div>
              )}
            </div>
            {!fetchingTeams && teams && teams.length > 0 && (
              <div className={styles.teams}>
                <h3 className={styles.teamsHeading}>Registrerade teams <span>({teams.length})</span></h3>
                <ul className={styles.teamsList}>
                  {teams.map(({ name, link_id, id }, index) => (
                    <li className={styles.teamsItem} key={index}>
                      <Link className={styles.teamsLink} to={`/challenges/${challengeId}/${link_id}/team/${id}`}>
                        {name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Content>
      ) : (
        <div>
          <Content>
            <p>Kunde inte hitta någon tävling med id {challengeId}</p>
            <Link to="/challenges">
              Tillbaka till listan
            </Link>
          </Content>
        </div>
      )}
      </section>
    </div>
  )
}
export default Challenge
import React from 'react'

function Cross({ color = "#3a3a3a", className }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd">
        <rect width="32" height="32"/>
        <path fill={color} d="M17.4149337,16.0010013 L23.4241463,9.99198932 C23.5252846,9.89085447 23.5252846,9.72663551 23.4241463,9.62550067 L22.3737107,8.57610147 C22.2735739,8.47496662 22.1083481,8.47496662 22.0082112,8.57610147 L15.9989986,14.5841121 L9.99178878,8.57510013 C9.89165192,8.47496662 9.72642612,8.47496662 9.62628926,8.57510013 L8.57985914,9.62149533 C8.47471544,9.72663551 8.47471544,9.88684913 8.57985914,9.99198932 L14.5850663,15.9989987 L8.57585367,22.0080107 C8.47471544,22.1091455 8.47471544,22.2733645 8.57585367,22.373498 L9.62628926,23.4238985 C9.72642612,23.5250334 9.89065056,23.5250334 9.99178878,23.4238985 L16,17.4148865 L22.0082112,23.4248999 C22.1083481,23.5250334 22.2735739,23.5250334 22.3737107,23.4248999 L23.4201409,22.373498 C23.5252846,22.2733645 23.5252846,22.1081442 23.4201409,22.0080107 L17.4149337,16.0010013 Z"/>
      </g>
    </svg>
  )
}

export default Cross
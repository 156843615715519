import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import styles from "../styles/Login.module.css";

function Login() {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        navigate("/challenges");
      }
    }
  }, [isLoading, isAuthenticated, navigate]);

  return isLoading ? (
    <div className={styles.page}>
      <p className={styles.loadingText}>Laddar…</p>
    </div>
  ) : !isAuthenticated ? (
    <div className={styles.loginPage}>
      <div className={styles.content}>
        <img className={styles.logo} alt="Ica Logotyp" src={logo} />
        <h1 className={styles.heading}>Anmäl team till Vegovalet</h1>
        <p className={styles.preamble}>
          Som lagkapten registrerar du först ett eget konto, skapar laget och
          anmäler dig själv och ditt team. En inbjudan kommer sen till er alla
          på mail.
        </p>
        <button
          className={styles.loginButton}
          onClick={() => loginWithRedirect()}
        >
          Nu kör vi!
        </button>
      </div>
    </div>
  ) : null;
}
export default Login;

import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios'
import Content from '../components/layout/Content'
import Input from '../components/Input'
import Arrow from '../icons/Arrow'
import Cross from '../icons/Cross'
import styles from '../styles/Team.module.css'

const API_URL = process.env.REACT_APP_API_BASEURL
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN

function Team() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { challengeId, linkId, teamId } = useParams()
  const [email, setEmail] = React.useState('')
  const [isValidEmail, setIsValidEmail] = React.useState('IDLE')
  const [team, setTeam] = React.useState(null)
  const [fetching, setFetching] = React.useState(false)
  
  React.useEffect(() => {
    let componentMounted = true;

    async function getTeam() {
      setFetching(true)
  
      try {

        const accessToken = await getAccessTokenSilently({
          audience: `https://${AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        });
        
        const { data } = await axios.get(`${API_URL}/links/${linkId}/teams/${teamId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        
        if(data && data.hasOwnProperty('members') && componentMounted) {
          setTeam({
            ...data.team,
            members: data.members
          })
        } else {
          setTeam(null)
        }
      } catch (error) {
        console.log(error)
        setTeam(null)
      }
      setFetching(false)
    }
    
    if(teamId && linkId){
      getTeam()
    }

    return () => {
      componentMounted = false
    }
    
  }, [linkId, teamId, getAccessTokenSilently])

  function validateEmail({ value }) {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value,
      )
    ) {
      setIsValidEmail('TRUE')
      return true
    } else {
      setIsValidEmail(value === '' ? 'IDLE' : 'ERROR')
      return false
    }
  }

  async function onAddMember() {
    const validEmail = validateEmail({ value: email })
    if(validEmail) {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        });
        const { data } = await axios.post(`${API_URL}/teams/${teamId}`, {
          members: [{
            email
          }]
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        if(data && data.hasOwnProperty('members')) {
          setTeam(prev => ({
            ...prev,
            members: data.members
          }))
        }
      } catch (error) {
        console.log(error)
      }
      setEmail('')
      setIsValidEmail('IDLE')
    }
  }

  async function onRemoveMember(id) {

    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${AUTH_DOMAIN}/api/v2/`,
        scope: "read:current_user",
      });

      const { data } = await axios.delete(`${API_URL}/members/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })

      if(data && data.hasOwnProperty('success') && data?.success === true) {
        setTeam(prev => {
          return {
            ...prev,
            members: prev.members.filter(member => member.id !== id)
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return isAuthenticated && (
    <div>
      <div className={styles.subHeader}>
        <Content>
          <Link className={styles.backButton} to={`/challenges/${challengeId}`}>
            <Arrow className={styles.backIcon} direction="left" color="#cf2e05" />
            Tillbaka
          </Link>
        </Content>
      </div>
      <section className={styles.team}>
      {fetching === true ? (
        <div className={styles.loading}>
          <Content>
            <p className={styles.loadingText}>Hämtar team…</p>
          </Content>
        </div>
      ) : team && (
        <Content>
          <h1 className={styles.heading}>{team?.name}</h1>
          <div className={styles.content}>
            {team?.description && (
              <div>
                <h3 className={styles.subHeading}>Lagbeskrivning</h3>
                <p className={styles.teamDescription}>{team?.description}</p>
              </div>
            )}
            {team?.members?.length > 0 && (
              <div>
                <h3 className={styles.subHeading}>Medlemmar</h3>
                <div className={styles.addEmail}>
                  <div className={styles.email}>
                    <label className={styles.label} htmlFor="email">E–post</label>
                    <Input isValid={isValidEmail} onBlur={() => validateEmail({ value: email })} id="email" type="email" placeholder="Ange e-post" value={email} onChange={e => setEmail(e.target.value)} />
                  </div>
                  <button className={styles.addEmailButton} type="button" onClick={onAddMember}>Lägg till</button>
                </div>
                <ul>
                  {team.members.map(({ email, id }, index) => (
                    <li className={styles.item} key={index}>
                      <button className={styles.removeButton} type="button" onClick={() => onRemoveMember(id)}>
                        <Cross color="#cf2e05" className={styles.removeIcon} />
                        <span className={styles.hidden}>
                          Ta bort
                        </span>
                      </button>
                      <span>{email}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Content>
      )}
      </section>
    </div>
  )
}
export default Team
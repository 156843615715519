import * as React from 'react';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Challenges from '../pages/Challenges'
import Challenge from '../pages/Challenge'
import CreateChallenge from '../pages/CreateChallenge'
import CreateTeam from '../pages/CreateTeam'
import Team from '../pages/Team'
import Login from '../pages/Login'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/challenges' element={<Challenges />} />
        <Route exact path='/challenges/create' element={<CreateChallenge />} />
        <Route exact path='/challenges/:challengeId' element={<Challenge />} />
        <Route exact path='/challenges/:linkId/team' element={<CreateTeam />} />
        <Route exact path='/challenges/:challengeId/:linkId/team/:teamId' element={<Team />} />
        <Route path="/" element={<Login />} />
      </Routes>
    </BrowserRouter>
    )
}

export default App
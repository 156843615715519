import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import Content from "../components/layout/Content";
import Input from "../components/Input";
import Cross from "../icons/Cross";
import styles from "../styles/CreateTeam.module.css";

const API_URL = process.env.REACT_APP_API_BASEURL;

function CreateTeam() {
  const { isAuthenticated } = useAuth0();
  const { linkId } = useParams();
  const navigate = useNavigate();

  const [name, setName] = React.useState("");
  const [isValidName, setIsValidName] = React.useState("IDLE");
  const [description, setDescription] = React.useState("");
  const [departments, setDepartments] = React.useState([]);
  const [loadingDepartments, setLoadingDepartments] = React.useState(false);
  const [department, setDepartment] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [creatorEmail, setCreatorEmail] = React.useState("");
  const [isValidCreatorEmail, setIsValidCreatorEmail] = React.useState("IDLE");
  const [isValidEmail, setIsValidEmail] = React.useState("IDLE");
  const [members, setMembers] = React.useState([]);
  const [isValidMembers, setIsValidMembers] = React.useState("IDLE");
  const [loading, setLoading] = React.useState(false);
  const [teamSuccessfullyCreated, setTeamSuccessfullyCreated] =
    React.useState(false);

  React.useEffect(() => {
    let componentMounted = true;

    async function getDepartments() {
      setLoadingDepartments(true);
      try {
        const { data } = await axios.get(`${API_URL}/departments`);
        if (data && data.hasOwnProperty("departments") && componentMounted) {
          setDepartment(data?.departments[0]?.id || "");
          setDepartments(data?.departments || []);
        }
      } catch (error) {
        console.log(error);
      }
      setLoadingDepartments(false);
    }
    getDepartments();

    return () => {
      componentMounted = false;
    };
  }, []);

  function onAddMember() {
    const validEmail = validateEmail({ value: email });
    if (validEmail) {
      setMembers((prev) => [email, ...prev]);
      setEmail("");
      setIsValidEmail("IDLE");
    }
  }

  function onRemoveMember(index) {
    setMembers((prev) => prev.filter((_, i) => i !== index));
  }

  function validateEmail({ value }) {
    const exists = members.find((member) => member === value);
    if (value !== "" && exists) {
      setIsValidEmail("ERROR");
      return false;
    }
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
    ) {
      setIsValidEmail("TRUE");
      return true;
    } else {
      setIsValidEmail(value === "" ? "IDLE" : "ERROR");
      return false;
    }
  }

  function validateName({ value }) {
    if (value && value.length > 1) {
      setIsValidName("TRUE");
      return true;
    } else {
      setIsValidName(value === "" ? "IDLE" : "ERROR");
      return false;
    }
  }

  function validateMembers({ value }) {
    if (value && value.length > 4) {
      setIsValidMembers("TRUE");
      return true;
    } else {
      setIsValidMembers("ERROR");
      return false;
    }
  }

  function validateCreatorEmail({ value }) {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
    ) {
      setIsValidCreatorEmail("TRUE");
      return true;
    } else {
      setIsValidCreatorEmail(value === "" ? "IDLE" : "ERROR");
      return false;
    }
  }

  function validateForm() {
    const validName = validateName({ value: name });
    const validMembers = validateMembers({ value: [...members, creatorEmail] });
    const validCreator = validateCreatorEmail({ value: creatorEmail });

    if (validName !== true || validMembers !== true || validCreator !== true) {
      if (validName !== true) {
        setIsValidName("ERROR");
      }
      if (validCreator !== true) {
        setIsValidCreatorEmail("ERROR");
      }
      return false;
    }
    return true;
  }

  async function onSubmit(e) {
    e.preventDefault();

    setLoading(true);
    const isValidForm = validateForm();

    if (isValidForm) {
      try {
        const { data } = await axios.post(`${API_URL}/links/${linkId}/teams`, {
          name,
          description,
          department,
          members: [
            ...members.map((member) => ({
              email: member,
            })),
            { email: creatorEmail },
          ],
        });
        console.log(data)
        if (isAuthenticated) {
          navigate("/challenges");
        } else {
          setTeamSuccessfullyCreated(true);
        }
      } catch (error) {
        console.log(error);
        console.log("Something went wrong while creating team");
        if (isAuthenticated) {
          navigate("/challenges");
        } else {
          setTeamSuccessfullyCreated(true);
        }
      }
    }
    setLoading(false);
  }

  return teamSuccessfullyCreated === true ? (
    <section className={styles.success}>
      <Content>
        <div className={styles.successContent}>
          <h2 className={styles.successHeading}>Team {name} skapat!</h2>
          <div className={styles.successPreamble}>
            <p>
              Nu väntar vi på att dina teammedlemmar ska vakna till liv. Meddela
              dem gärna att de ska spana in sin inkorg (eller i vissa fall
              Skräppost). Och glöm inte att kolla själv!
            </p>
            <p>
              Som lagkapten ansvarar du för att hitta sätt för dig och ditt team
              att peppa varandra längs vägen! T ex "Vegovalet-checkin" på
              veckomötet eller dela bilder/tips/recept i en Teams-chatt!
            </p>
            <p>Lycka till med utmaningen!</p>
            <p>Du kan nu stänga detta fönster.</p>
          </div>
          <a
            className={styles.successButton}
            href="https://app.vegovalet.se"
          >
            Fortsätt
          </a>
        </div>
      </Content>
    </section>
  ) : (
    <section className={styles.createTeam}>
      <Content>
        <h1 className={styles.heading}>Skapa Team</h1>
        <div className={styles.content}>
          <form onSubmit={onSubmit}>
            <div className={styles.formGroup}>
              <div>
                <label className={styles.labelHeading} htmlFor="name">
                  Lagnamn
                </label>
                <Input
                  isValid={isValidName}
                  onBlur={() => validateName({ value: name })}
                  id="name"
                  type="text"
                  placeholder="Ange lagnamn"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <label className={styles.label} htmlFor="description">
                  Beskrivning
                </label>
                <textarea
                  className={styles.textarea}
                  id="description"
                  value={description}
                  placeholder="Beskriv teamet här"
                  onChange={(e) => setDescription(e.target.value)}
                  style={{ resize: "none" }}
                />
              </div>
              <div>
                <label className={styles.label} htmlFor="departments">
                  Bolag
                </label>
                {loadingDepartments ? (
                  <p className={styles.loadingDepartments}>
                    Hämtar avdelningar…
                  </p>
                ) : (
                  <>
                    {departments.length > 0 && (
                      <div>
                        <select
                          className={styles.dropdown}
                          onChange={(e) =>
                            setDepartment(parseInt(e.target.value))
                          }
                          value={department}
                          name="departments"
                          id="departments"
                        >
                          {departments.map(({ id, name }, index) => (
                            <option key={index} value={id}>
                              {name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div>
              <label className={styles.labelHeading} htmlFor="name">
                Lagkapten e-post
              </label>
              <Input
                isValid={isValidCreatorEmail}
                onBlur={() => validateCreatorEmail({ value: creatorEmail })}
                id="name"
                type="text"
                placeholder="Ange lagkaptenens e-post"
                value={creatorEmail}
                onChange={(e) => setCreatorEmail(e.target.value)}
              />
            </div>
            <div className={styles.formGroup}>
              <h3 className={styles.formHeading}>
                Lägg till minst 4 medlemmar
              </h3>
              <div className={styles.addEmail}>
                <div className={styles.email}>
                  <label className={styles.label} htmlFor="email">
                    E–post
                  </label>
                  <Input
                    isValid={isValidEmail}
                    onBlur={() => validateEmail({ value: email })}
                    id="email"
                    type="email"
                    placeholder="Ange e-post"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <button
                  className={styles.addEmailButton}
                  type="button"
                  onClick={onAddMember}
                >
                  Lägg till
                </button>
              </div>
              {isValidMembers === "ERROR" && (
                <p className={styles.errorMessage}>
                  Ett team måste bestå av minst 5 medlemmar
                </p>
              )}
            </div>
            {members && members.length > 0 && (
              <>
                <h3 className={styles.formHeading}>Medlemmar</h3>
                <ul className={styles.list}>
                  {members.map((member, index) => (
                    <li className={styles.item} key={index}>
                      <button
                        className={styles.removeButton}
                        type="button"
                        onClick={() => onRemoveMember(index)}
                      >
                        <Cross color="#cf2e05" className={styles.removeIcon} />
                        <span className={styles.hidden}>Ta bort</span>
                      </button>
                      <span>{member}</span>
                    </li>
                  ))}
                </ul>
              </>
            )}
            <div className={styles.action}>
              <button
                className={styles.createButton}
                disabled={loading}
                type="submit"
              >
                Skapa Team
              </button>
            </div>
          </form>
        </div>
      </Content>
    </section>
  );
}
export default CreateTeam;

import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios'
import Content from '../components/layout/Content'
import Input from '../components/Input'
import Arrow from '../icons/Arrow'
import styles from '../styles/CreateChallenge.module.css'

const API_URL = process.env.REACT_APP_API_BASEURL
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN

function CreateChallenge() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [name, setName] = React.useState('')
  const [isValidName, setIsValidName] = React.useState('IDLE')
  const [label, setLabel] = React.useState('')
  const [isValidLabel, setIsValidLabel] = React.useState('IDLE')
  const [dateFrom, setDateFrom] = React.useState('')
  const [isValidDateFrom, setIsValidDateFrom] = React.useState('IDLE')
  const [dateTo, setDateTo] = React.useState('')
  const [isValidDateTo, setIsValidDateTo] = React.useState('IDLE')
  const [firstQuestion, setFirstQuestion] = React.useState('')
  const [isValidFirstQuestion, setIsValidFirstQuestion] = React.useState('IDLE')
  const [firstLabel, setFirstLabel] = React.useState('')
  const [isValidFirstLabel, setIsValidFirstLabel] = React.useState('IDLE')
  const [firstMin, setFirstMin] = React.useState(0)
  const [isValidFirstMin, setIsValidFirstMin] = React.useState('TRUE')
  const [firstMax, setFirstMax] = React.useState(10)
  const [isValidFirstMax, setIsValidFirstMax] = React.useState('TRUE')
  const [secondQuestion, setSecondQuestion] = React.useState('')
  const [isValidSecondQuestion, setIsValidSecondQuestion] = React.useState('IDLE')
  const [secondLabel, setSecondLabel] = React.useState('')
  const [isValidSecondLabel, setIsValidSecondLabel] = React.useState('IDLE')
  const [secondMin, setSecondMin] = React.useState(0)
  const [isValidSecondMin, setIsValidSecondMin] = React.useState('TRUE')
  const [secondMax, setSecondMax] = React.useState(10)
  const [isValidSecondMax, setIsValidSecondMax] = React.useState('TRUE')
  const [thirdQuestion, setThirdQuestion] = React.useState('')
  const [isValidThirdQuestion, setIsValidThirdQuestion] = React.useState('IDLE')
  const [thirdLabel, setThirdLabel] = React.useState('')
  const [isValidThirdLabel, setIsValidThirdLabel] = React.useState('IDLE')
  const [thirdMin, setThirdMin] = React.useState(0)
  const [isValidThirdMin, setIsValidThirdMin] = React.useState('TRUE')
  const [thirdMax, setThirdMax] = React.useState(10)
  const [isValidThirdMax, setIsValidThirdMax] = React.useState('TRUE')
  const [loading, setLoading]  = React.useState(false)
  const navigate = useNavigate()


  function validateTextField({ value, setValidationStatus }) {
    if(value && value.length > 1) {
      setValidationStatus('TRUE')
      return true
    } else {
      setValidationStatus(value === '' ? 'IDLE' : 'ERROR')
      return false
    }
  }

  function validateMinMax({ min, max, setMinValidationStatus, setMaxValidationStatus  }) {
    if(typeof min !== 'number' || typeof max !== 'number') {
      if(typeof min !== 'number') {
        setMinValidationStatus('ERROR')
      }
      if(typeof max !== 'number') {
        setMaxValidationStatus('ERROR')
      }
      return false
    } else {
      if(min < max) {
        setMinValidationStatus('TRUE')
        setMaxValidationStatus('TRUE')
        return true
      } else {
        setMinValidationStatus('ERROR')
        setMaxValidationStatus('ERROR')
        return false
      }
    }
  }

  function validateDate({ value, setValidationStatus}) {
    
    try {
     const date = new Date(value).toString()
     if(date !== 'Invalid Date') {
      setValidationStatus('TRUE')
      return true
     } else {
      setValidationStatus('ERROR')
       return false
     }
    } catch (error) {
      console.log(error)
      setValidationStatus('ERROR')
      return false
    }
  }

  function validateForm() {
    const validName = validateTextField({ value: name, setValidationStatus: setIsValidName })
    const validLabel = validateTextField({ value: label, setValidationStatus: setIsValidLabel })
    const validDateFrom = validateDate({ value: dateFrom, setValidationStatus: setIsValidDateFrom })
    const validDateTo = validateDate({ value: dateTo, setValidationStatus: setIsValidDateTo })
    const validFirstQuestion = validateTextField({ value: firstQuestion, setValidationStatus: setIsValidFirstQuestion })
    const validFirstLabel = validateTextField({ value: firstLabel, setValidationStatus: setIsValidFirstLabel })
    const validSecondQuestion = validateTextField({ value: secondQuestion, setValidationStatus: setIsValidSecondQuestion })
    const validSecondLabel = validateTextField({ value: secondLabel, setValidationStatus: setIsValidSecondLabel })
    const validThirdQuestion = validateTextField({ value: thirdQuestion, setValidationStatus: setIsValidThirdQuestion })
    const validThirdLabel = validateTextField({ value: thirdLabel, setValidationStatus: setIsValidThirdLabel })
    const validFirstMinMax = validateMinMax({ min: parseInt(firstMin), max: parseInt(firstMax), setMinValidationStatus: setIsValidFirstMin, setMaxValidationStatus: setIsValidFirstMax })
    const validSecondMinMax = validateMinMax({ min: parseInt(secondMin), max: parseInt(secondMax), setMinValidationStatus: setIsValidSecondMin, setMaxValidationStatus: setIsValidSecondMax })
    const validThirdMinMax = validateMinMax({ min: parseInt(thirdMin), max: parseInt(thirdMax), setMinValidationStatus: setIsValidThirdMin, setMaxValidationStatus: setIsValidThirdMax })

    if(validName !== true || validLabel !== true || validDateFrom !== true || validDateTo !== true || validFirstQuestion !== true || validFirstLabel !== true || validSecondQuestion !== true || validSecondLabel !== true || validThirdQuestion !== true || validThirdLabel !== true || validFirstMinMax !== true || validSecondMinMax !== true || validThirdMinMax !== true) {
      if(validName !== true) {
        setIsValidName('ERROR')
      }
      if(validLabel !== true) {
        setIsValidLabel('ERROR')
      }
      if(validDateFrom !== true) {
        setIsValidDateFrom('ERROR')
      }
      if(validDateTo !== true) {
        setIsValidDateTo('ERROR')
      }
      if(validFirstQuestion !== true) {
        setIsValidFirstQuestion('ERROR')
      }
      if(validFirstLabel !== true) {
        setIsValidFirstLabel('ERROR')
      }
      if(validSecondQuestion !== true) {
        setIsValidSecondQuestion('ERROR')
      }
      if(validSecondLabel !== true) {
        setIsValidSecondLabel('ERROR')
      }
      if(validThirdQuestion !== true) {
        setIsValidThirdQuestion('ERROR')
      }
      if(validThirdLabel !== true) {
        setIsValidThirdLabel('ERROR')
      }
      if(validFirstMinMax !== true) {
        setIsValidFirstMin('ERROR')
        setIsValidFirstMax('ERROR')
      }
      if(validSecondMinMax !== true) {
        setIsValidSecondMin('ERROR')
        setIsValidSecondMax('ERROR')
      }
      if(validThirdMinMax !== true) {
        setIsValidThirdMin('ERROR')
        setIsValidThirdMax('ERROR')
      }
      return false
    }
    return true
  
  }

  async function onSubmit(e) {
    e.preventDefault()
    setLoading(true)
    const isValidForm = validateForm()

    if(isValidForm) {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        });
        
        const { data } = await axios.post(`${API_URL}/challenges`, {
          name,
          label,
          dateFrom,
          dateTo,
          questions: [{
            question: firstQuestion,
            label: firstLabel,
            min: firstMin,
            max: firstMax
          },
          {
            question: secondQuestion,
            label: secondLabel,
            min: secondMin,
            max: secondMax
          },
          {
            question: thirdQuestion,
            label: thirdLabel,
            min: thirdMin,
            max: thirdMax
          }]
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        if(data && data.hasOwnProperty('challenge')) {
          console.log(`Challenge ${data.challenge?.id} was successfully crerated`);
          navigate(`/challenges/${data.challenge.id}`)
        } else {
          console.log('Something went wrong while creating challenge');
        }
    
    } catch (error) {
      console.log(error)
      console.log('Something went wrong while creating challenge');
    }
    }
    setLoading(false)
  }

  return isAuthenticated && (
    <div>
      <div className={styles.subHeader}>
        <Content>
          <Link className={styles.backButton} to="/challenges">
            <Arrow className={styles.backIcon} direction="left" color="#cf2e05" />
            Tillbaka
          </Link>
        </Content>
      </div>
      <section className={styles.createChallenge}>
        <Content>
          <h1 className={styles.heading}>Skapa tävling</h1>
          <div className={styles.content}>
            <form onSubmit={onSubmit}>
              <div>
                <div>
                  <label className={styles.labelHeading} htmlFor="name">Tävlingsnamn</label>
                  <Input isValid={isValidName} onBlur={() => validateTextField({ value: name, setValidationStatus: setIsValidName })} id="name" type="text" placeholder="Namn på tävlingen" value={name} onChange={e => setName(e.target.value)} />
                </div>
                <div>
                  <label className={styles.label} htmlFor="label">Label</label>
                  <Input isValid={isValidLabel} onBlur={() => validateTextField({ value: label, setValidationStatus: setIsValidLabel })} id="label" type="text" placeholder="Label" value={label} onChange={e => setLabel(e.target.value)} />
                </div>
              </div>
              <div className={styles.period}>
                <div className={styles.date}>
                  <label className={styles.label} htmlFor="dateFrom">Startdatum</label>
                  <Input isValid={isValidDateFrom} onBlur={() => validateDate({ value: dateFrom, setValidationStatus: setIsValidDateFrom })} placeholder="YYYY-MM-DD" id="dateFrom" type="text" value={dateFrom} onChange={e => setDateFrom(e.target.value)} />
                </div>
                <div className={styles.date}>
                  <label className={styles.label} htmlFor="dateTo">Slutdatum</label>
                  <Input isValid={isValidDateTo} onBlur={() => validateDate({ value: dateTo, setValidationStatus: setIsValidDateTo })} placeholder="YYYY-MM-DD" id="dateTo" type="text" value={dateTo} onChange={e => setDateTo(e.target.value)} />
                </div>
              </div>
              <div className={styles.question}>
                <label className={styles.labelHeading} htmlFor="firstQuestion">Fråga 1</label>
                <Input isValid={isValidFirstQuestion} onBlur={() => validateTextField({ value: firstQuestion, setValidationStatus: setIsValidFirstQuestion })} id="firstQuestion" type="text" placeholder="Ange den första frågan" value={firstQuestion} onChange={e => setFirstQuestion(e.target.value)} />
                <div className={styles.questionOptions}>
                  <div className={styles.questionLabel}>
                    <label className={styles.label} htmlFor="firstLabel">Label</label>
                    <Input isValid={isValidFirstLabel} onBlur={() => validateTextField({ value: firstLabel, setValidationStatus: setIsValidFirstLabel })} id="firstLabel" type="text" placeholder="Ange label" value={firstLabel} onChange={e => setFirstLabel(e.target.value)} />
                  </div>
                  <div className={styles.grades}>
                    <div className={styles.grade}>
                      <label className={styles.label} htmlFor="firstMin">Minsta svarsvärde</label>
                      <Input isValid={isValidFirstMin} onBlur={() => validateMinMax({ min: parseInt(firstMin), max: parseInt(firstMax), setMinValidationStatus: setIsValidFirstMin, setMaxValidationStatus: setIsValidFirstMax })} id="firstMin" type="number" placeholder="Min" value={firstMin} onChange={e => setFirstMin(e.target.value)} />
                    </div>
                    <div className={styles.grade}>
                      <label className={styles.label} htmlFor="firstMax">Högsta svarsvärde</label>
                      <Input isValid={isValidFirstMax} onBlur={() => validateMinMax({ min: parseInt(firstMin), max: parseInt(firstMax), setMinValidationStatus: setIsValidFirstMin, setMaxValidationStatus: setIsValidFirstMax })} id="firstMax" type="number" placeholder="Max" value={firstMax} onChange={e => setFirstMax(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.question}>
                <label className={styles.labelHeading} htmlFor="secondQuestion">Fråga 2</label>
                <Input isValid={isValidSecondQuestion} onBlur={() => validateTextField({ value: secondQuestion, setValidationStatus: setIsValidSecondQuestion })} id="secondQuestion" type="text" placeholder="Ange den andra frågan" value={secondQuestion} onChange={e => setSecondQuestion(e.target.value)} />
                <div className={styles.questionOptions}>
                  <div className={styles.questionLabel}>
                    <label className={styles.label} htmlFor="secondLabel">Label</label>
                    <Input isValid={isValidSecondLabel} onBlur={() => validateTextField({ value: secondLabel, setValidationStatus: setIsValidSecondLabel })} id="secondLabel" type="text" placeholder="Ange label" value={secondLabel} onChange={e => setSecondLabel(e.target.value)} />
                  </div>
                  <div className={styles.grades}>
                    <div className={styles.grade}>
                      <label className={styles.label} htmlFor="secondMin">Minsta svarsvärde</label>
                      <Input isValid={isValidSecondMin} onBlur={() => validateMinMax({ min: parseInt(secondMin), max: parseInt(secondMax), setMinValidationStatus: setIsValidSecondMin, setMaxValidationStatus: setIsValidSecondMax })} id="secondMin" type="number" placeholder="Min" value={secondMin} onChange={e => setSecondMin(e.target.value)} />
                    </div>
                    <div className={styles.grade}>
                      <label className={styles.label} htmlFor="secondMax">Högsta svarsvärde</label>
                      <Input isValid={isValidSecondMax} onBlur={() => validateMinMax({ min: parseInt(secondMin), max: parseInt(secondMax), setMinValidationStatus: setIsValidSecondMin, setMaxValidationStatus: setIsValidSecondMax })} id="secondMax" type="number" placeholder="Max" value={secondMax} onChange={e => setSecondMax(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.question}>
                <label className={styles.labelHeading} htmlFor="thirdQuestion">Fråga 3</label>
                <Input isValid={isValidThirdQuestion} onBlur={() => validateTextField({ value: thirdQuestion, setValidationStatus: setIsValidThirdQuestion })} id="thirdQuestion" type="text" placeholder="Ange den tredje frågan" value={thirdQuestion} onChange={e => setThirdQuestion(e.target.value)} />
                <div className={styles.questionOptions}>
                  <div className={styles.questionLabel}>
                    <label className={styles.label} htmlFor="thirdLabel">Label</label>
                    <Input isValid={isValidThirdLabel} onBlur={() => validateTextField({ value: thirdLabel, setValidationStatus: setIsValidThirdLabel })} id="thirdLabel" type="text" placeholder="Ange label" value={thirdLabel} onChange={e => setThirdLabel(e.target.value)} />
                  </div>
                  <div className={styles.grades}>
                    <div className={styles.grade}>
                      <label className={styles.label} htmlFor="thirdMin">Minsta svarsvärde</label>
                      <Input isValid={isValidThirdMin} onBlur={() => validateMinMax({ min: parseInt(thirdMin), max: parseInt(thirdMax), setMinValidationStatus: setIsValidThirdMin, setMaxValidationStatus: setIsValidThirdMax })} id="thirdMin" type="number" placeholder="Min" value={thirdMin} onChange={e => setThirdMin(e.target.value)} />
                    </div>
                    <div className={styles.grade}>
                      <label className={styles.label} htmlFor="thirdMax">Högsta svarsvärde</label>
                      <Input isValid={isValidThirdMax} onBlur={() => validateMinMax({ min: parseInt(thirdMin), max: parseInt(thirdMax), setMinValidationStatus: setIsValidThirdMin, setMaxValidationStatus: setIsValidThirdMax })} id="thirdMax" type="number" placeholder="Max" value={thirdMax} onChange={e => setThirdMax(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.action}>
                <button className={styles.createButton} disabled={loading} type="submit">
                  Skapa tävling
                </button>
              </div>
            </form>
          </div>
        </Content>
      </section>
    </div>
  )
}
export default CreateChallenge
import Content from './Content'
import styles from '../../styles/Header.module.css'

function Header({ children }) {
  return (
    <header className={styles.header}>
      <Content style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
        <span className={styles.title}>Vegovalet</span>
        <div>
          {children}
        </div>
      </Content>
    </header>
  )
}

export default Header